export const COLORS = {
    PRIMARY1 : "#273457",
    PRIMARY2 : "#141E2E",
    PRIMARY3 : "#252E44",
    BUTTONS1 : "#545E77",
    BUTTONS2 : "#008AFF",
    SELECTED : "#2EE3FF",
    PROGRESSBAR : "#FB471F",
    WHITE : "#FFFFFF",
    RED : "#FF0000",
    SIDEBARBG : "#0A121E",
    COMPANY : "#ED7422",
    BORDER : "#535D76",
    TOGGLE : "#1C75C4",
};

type ObjType = {
    [index: string]: string;
// CP: string;
// EU: string;
};
export const LEVELS: ObjType = {
    CP: "#548037",
    EU: COLORS.BUTTONS1,
};
