import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS } from "../../../styles/colors";
import InfoSVG from "assets/svg/info.svg";
import { useState, useEffect, useRef } from "react";
import InfoPopup from "../popup/InfoPopup";

export const Text = styled.div`
  color: ${(props) => (props.color ? props.color : COLORS.WHITE)};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "16px")};
  white-space: nowrap;
  ${(props) =>
    props.bold &&
    `
      font-weight: bold;
    `};
`;

export const UnderlineText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

const InputLabelBox = styled.label`
  ${FLEX.FlexStartCenter};
`;

const EssentialPoint = styled.span`
  color: ${COLORS.RED};
`;

export const InfoContainer = styled.div`
  position: relative;
`;

const InfoIcon = styled.img`
  padding-left: 5px;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: -28px;
`;

export function InputLabel({ label, essential, info, contents }) {
  const [isInfoPopup, setIsInfoPopup] = useState(false);
  const infoRef = useRef(null);

  useEffect(() => {
    const onClick = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        setIsInfoPopup(false);
      }
    };

    document.body.addEventListener("click", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [infoRef]);

  return (
    <InputLabelBox>
      <Text bold fontSize={18}>
        {label}
      </Text>
      {essential && <EssentialPoint>*</EssentialPoint>}
      {info && (
        <InfoContainer>
          <InfoIcon
            ref={infoRef}
            src={InfoSVG}
            alt="info icon svg"
            onClick={() => setIsInfoPopup(!isInfoPopup)}
          ></InfoIcon>
          {isInfoPopup && (
            <InfoWrapper>
              <InfoPopup contents={contents} />
            </InfoWrapper>
          )}
        </InfoContainer>
      )}
    </InputLabelBox>
  );
}
