import styled from "styled-components";
import { COLORS } from "styles/colors";
const Input = styled.input`
  width: 100%;
  max-height: 38px;
  border-radius: 4px;
  padding: 30px 20px;
  box-sizing: border-box;
  border: 1px solid #828c9e;

  ::placeholder {
    color: #abb5c5;
    font-size: 16px;
  }
  :disabled {
    background-color: ${COLORS.BUTTONS1};
    color: rgba(255, 255, 255, 0.75);
  }
`;

export default Input;
