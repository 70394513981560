import {useCallback, useEffect, useState} from "react";
import * as s from "./HeaderStyled";
import {useNavigate} from "react-router-dom";
import {webProxyApi} from "api/WebProxyApi";
import {NvrInfoApi} from "api/NvrInfoApi";
import {useAuth} from "context/auth";
import {Modal} from "antd";

import UserSVG from "assets/svg/user.svg";
import LogoSmallSVG from "assets/logo/mydw_small.svg";
import RefreshSVG from "assets/svg/refresh.svg";
import {RefreshTooltip} from "./HeaderStyled";

function Header(props) {
    const navigate = useNavigate();
    const auth = useAuth();

    const [isDropdown, setIsDropdown] = useState(false);
    const [setupUrl, setSetupUrl] = useState("");
    const [webInfo, setWebInfo] = useState([]);
    const [isSupportOpen, setIsSupportOpen] = useState(null);
    const [isSetupOpen, setIsSetupOpen] = useState(null);
    const [isRefeshOver, setIsRefreshOver] = useState(false);

    const onDropdown = () => {
        setIsDropdown(!isDropdown);
    };

    const openSupport = () => {

        let button = document.getElementById('support');
        button.disabled = true;

            if (isSupportOpen === null) {

                setIsSupportOpen(window.open("https://digital-watchdog.com/contact-tech-support/"));
            } else {
                isSupportOpen.close();
                setIsSupportOpen(window.open("https://digital-watchdog.com/contact-tech-support/"));
            }
            setTimeout(()=>{
                button.disabled = false;
            },3000)
        //}
    }
    let setupOpen = null;

    const openSetup = async () => {
        const button = document.getElementById('setup');
        button.disabled = true;
        let webIp
        let webPort
        let generationUrl = null;

        if(isSetupOpen === null){
            setupOpen = window.open()
        }
        else{
            isSetupOpen.close();
            setupOpen = window.open()

        }
        setIsSetupOpen(setupOpen)

        setTimeout(()=>{
            button.disabled = false;
        },3000)

        try {
            let webResult = null;
            ({data: webResult} = await webProxyApi.webServerInfoApi(props.pid));

            webIp = webResult.address;
            webPort = webResult.port;


            try {

                await NvrInfoApi.getTimeHostApi(`${webIp}:${webPort}`, props.userID, props.password)
                    .then(data => {
                        generationUrl = `http://${webIp}:${webPort}/cgi-bin/login_ex?login_type=0&hidesetupheader=1&logout_type=1&time=${data.data.time}&uid=${data.data.uid}&key=${data.data.key}`;
                        //console.log(sha256(`__${props.userID}__${data.data.time}__EnCrYpTeD_$_fOcUsHnS_0801_!_J0924__`).toUpperCase())

                        setupOpen.location = generationUrl;

                    })


            } catch (error) {
                console.log(error)
            }

        } catch (error) {
            return;
        }

    }

    const onClickDropdown = (e) => {
        e.preventDefault();
        // console.log(isSupportOpen)
        // console.log(isSetupOpen)
        auth.logout();
        if (isSupportOpen !== null) {
            isSupportOpen.close();
        }

        if (isSetupOpen !== null) {
            isSetupOpen.close()
        }

        // navigate('/');
        window.Module._uweb_disConnect();
        window.location.href = "https://"+window.location.hostname

    }

    const preventGoBack = (e) => {
        e.preventDefault();
        // window.history.pushState(null, "", "https://"+ window.location.href);
        // window.history.pushState(null, "", "https://"+ window.location.hostname);
        auth.logout();
        if (isSupportOpen !== null) {
            isSupportOpen.close();
        }

        if (isSetupOpen !== null) {
            isSetupOpen.close();
        }
        window.location.href = "https://"+ window.location.hostname



    };

    const openReload = () =>{
        window.location.reload()
    }

    const onExtendClose = (e) => {
        //console.log(isSetupOpen)
        e.preventDefault();
        auth.logout();
        if (isSupportOpen !== null) {
            isSupportOpen.close();
        }

        if (isSetupOpen !== null) {
            isSetupOpen.close();
        }
        window.location.href = "https://"+ window.location.hostname
    }

    function extendTimer(){

        setInterval(() => {

            //setInterval(() => {
            let secondsToGo = 300;
            // let secondsToGo = 10;
            const modal = Modal.warning({
                title: 'You will be logged out without any prolonged activity.\nPress the extend button to extend the session.',
                content: `Time remaining before automatic logout ${secondsToGo} SEC.`,
                width: 520,
                okText : 'extend',
                onOk: () => {deleteTimer()},

            });
            const timer = setInterval(() => {
                secondsToGo -= 1;
                modal.update({
                    content: `Time remaining before automatic logout ${secondsToGo} SEC.`,
                });
            }, 1000);
            const setTime = setTimeout(() => {
                clearInterval(timer);
                modal.destroy();
                window.location.href = "https://"+ window.location.hostname
                //onExtendClose();
                // auth.logout();
                // if (isSupportOpen !== null) {
                //     isSupportOpen.close();
                // }
                //
                // if (isSetupOpen !== null) {
                //     isSetupOpen.close();
                // }
                // window.location.href = "https://" + window.location.hostname;

            }, secondsToGo * 1000);

            const deleteTimer = () => {
                clearTimeout(setTime);
            }
        // },30000);

       }, 900000);


    }

    useEffect(() => {
        extendTimer();

        const script = document.createElement('script');
        script.src = "/js/qtloader.min.js";
        document.body.appendChild(script);


        (() => {

            // window.history.pushState(null, "", "https://"+ window.location.hostname);
            //window.history.pushState(null, "", "https://"+ window.location.href);

            window.addEventListener("popstate", preventGoBack);

        })();


        // return () => {
        //
        //     window.removeEventListener("popstate", preventGoBack);
        //
        // };


    },[]);

    // const navigate = useNavigate();
    // const location = useLocation();
    //const pid = props.pid
    //console.log(pid)
    return (
        <>
            <s.HeaderContainer>
                <s.LeftSide>
                    <s.Logo>
                        <img src={LogoSmallSVG} alt="logo small icon"/>
                        <h1>MONITORING</h1>
                    </s.Logo>

                    <div className={"refresh-button"}>
                        <button
                            onMouseOver={() => setIsRefreshOver(true)}
                            onMouseLeave={() => setIsRefreshOver(false)}
                            onClick={openReload}
                        >
                            <img src={RefreshSVG} alt={"refresh-icon"}/>
                        </button>

                        {
                            isRefeshOver && (
                                <s.RefreshTooltip>Refresh Camera Channels</s.RefreshTooltip>
                            )
                        }
                    </div>

                </s.LeftSide>
                <s.RightSide>
                    {/* onClick 이벤트 무엇인지 안나와있음 */}
                    <s.RightMenu id="setup" blue onClick={openSetup}>Setup</s.RightMenu>
                    {/*<s.RightMenu id="reload" onClick={openReload}>Reload</s.RightMenu>*/}
                    <s.RightMenu id="support" onClick={openSupport}>Support</s.RightMenu>
                    <s.RightMenu onClick={onDropdown}>
                        <img src={UserSVG} alt="user icon"/>
                        {props.userID}
                    </s.RightMenu>
                </s.RightSide>
            </s.HeaderContainer>
            {isDropdown && (
                <s.Dropdown>
                    <s.DropdownItem onClick={onClickDropdown}>Sign out</s.DropdownItem>
                </s.Dropdown>
            )}
        </>
    );
}

export default Header;
