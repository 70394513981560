import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { COLORS } from "./colors";

// const defaultRadius = "4px";
// const defaultPadding = "10px";

export const GlobalStyle = createGlobalStyle`
    ${reset};
    /* HTML5 display-role reset for older browsers */
    * {
        margin: 0;
        padding: 0;
        line-height: none;
    }

    body {
        box-sizing: border-box;
        font-family: 'Arial', sans-serif;
    }
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    ol, ul, li {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /*
    */

    button {
        background: none;
        border: 0;
        cursor: pointer;
        min-width: max-content;

        &:focus {
            outline: none;
        }
    }

    span {
        font-weight: normal;
    }

    a {
        text-decoration: none;
        color: #000;

        &:active {
            color: none;
        }
    }

    strong {
        font-weight: bold;
    }


    img {
        border: none;
    }
    
    textarea:focus, input:focus {
      outline: none;
    }

    input[type='checkbox'],
    input[type='checkbox']:checked,
    input[type='radio'],
    input[type='radio']:checked
     {
        appearance: none;
    }


    input[type='checkbox'] {
        background-color: ${COLORS.WHITE};
        width: 20px;
        height: 20px;
        border: 1px solid #ACACAC;
    }
    input[type='checkbox']:checked {
        background-color: ${COLORS.SELECTED};
        border: none;
        background: ${COLORS.SELECTED} no-repeat center center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.607' height='11.639'%3E%3Cpath data-name='Icon awesome-check' d='M5.3 11.41.229 6.338a.78.78 0 0 1 0-1.1l1.1-1.1a.78.78 0 0 1 1.1 0l3.417 3.417L13.165.236a.78.78 0 0 1 1.1 0l1.1 1.1a.78.78 0 0 1 0 1.1L6.4 11.41a.78.78 0 0 1-1.1 0Z' fill='%23fff'/%3E%3C/svg%3E");
    }
    input[type='radio'] {
        background-color: ${COLORS.WHITE};
        width: 20px;
        height: 20px;
        border-radius: 50%;
        outline: 2px solid ${COLORS.SELECTED};
    }
    input[type='radio']:checked {
        background-color: ${COLORS.SELECTED};
        border: 2px solid ${COLORS.WHITE};
        padding: 2px;
        outline: 2px solid ${COLORS.SELECTED};
        box-sizing:border-box;
    }
    
`;
