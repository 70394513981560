import { Route, Routes } from "react-router-dom";
import Login from "pages/login";
import Monitoring from "pages/monitoring";
import Monitoring_ from "pages/monitoring/Monitoring2";
import Mydw from "pages/mydw"

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/monitoring" element={<Monitoring />} />
      <Route path="/monitoring_" element={<Monitoring_ />}/>
        <Route path="/mydw" element={<Mydw />}/>
    </Routes>
  );
};
export default Router;
