import styled from "styled-components";
import loginBg from "assets/images/loginBg.png";
import { FLEX } from "styles/flex";
import {COLORS} from "../../styles/colors";

export const Container = styled.figure`
  width: 100vw;
  min-height: 100vh;
  background: #090B0C no-repeat fixed center center;
  background-size: cover;
  position: relative;
`;

export const Monitor = styled.canvas`
  //width: 100vw;
  //width: 100%;
  width: calc(100vw - 18px);
  //width:1080px;
  //width: 99%;
  //height: 99%;
  height: calc(100vh - 100px);
  //height: 100vh;
  //height:720px;
  //padding-bottom: 20px;
  //background: #252E44 no-repeat fixed center center;
  //background-color: #252E44;
  //padding-top: 50px;
`;

export const StatusWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  ${FLEX.FlexCenterCenter};
  position: absolute;
`;

export const Status = styled.div`
  //height: 60px;
  //width: 60px;
  //margin: auto;
  font-size: 2.0rem;
  color: ${COLORS.COMPANY};
`;

export const MonitorWrapper = styled.div`
  width: 100%;
  padding-top: 60px;
  ${FLEX.FlexStartCenter};
`;

export const RefreshWrapper = styled.div`
  background-color: #212A2F;
  border-radius: 8px;
  border: 1px solid #6A7385;
  padding: 20px;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 14px;
  color: ${COLORS.WHITE};
  
  > img {
    width: 90px;
  }
`;
//
// export const SpinnerContainer = styled.div`
//   width: 100vw;
//   height: calc(100% - 60px);
//   background-color: rgba(0,0,0,50);
// `;
