import {useNavigate, useLocation} from "react-router-dom";
import Header_ from "components/blocks/header/Header2";
import * as s from "./MonitoringStyled";
import {useState, useEffect} from "react";
import {useAuth} from "context/auth";
import {wsProxyApi} from "../../api/WsProxyApi";
import { Modal } from "antd";
import {MonitorWrapper, StatusWrapper} from "./MonitoringStyled";

const Monitoring_ = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const pid = location.state.pid;
    const userID = location.state.userID;
    const password = location.state.password;
    // const wsIP = location.state.wsIP;
    // const wsPort = location.state.wsPort;
    const channel = location.state.channel;
    const auth = useAuth();

    let wasmCheck = false;
    let timeoutCheck = false;
    let timeoutCnt = true;

    const [isLoading, setIsLoading] = useState(true);


    async function  web_viewer_init() {

        let streamType = Number("0");
        let wsIp ;
        let wsPort ;

        var spinner = document.querySelector('#qtspinner');
        var canvas = document.querySelector('#qtcanvas');
        var status = document.querySelector('#qtstatus');

        try {

            let wsResult = null;
            ({data: wsResult} = await wsProxyApi.wsServerInfoApi(pid));

            wsIp = wsResult.address;
            wsPort = wsResult.port;


        } catch (error) {
            console.log(error);
            return;
        }

    // eslint-disable-next-line no-undef
        var qtLoader = QtLoader({
            canvasElements: [canvas],
            showLoader: function (loaderStatus) {
                // spinner.style.display = 'block';
                // canvas.style.display = 'none';
                // status.innerHTML = "<font color=#ff8127>Loading...</font>";
            },
            showError: function (errorText) {
                // status.innerHTML = "<font color=yellow>" + errorText + "</font>";
                // spinner.style.display = 'block';
                // canvas.style.display = 'none';
            },
            showExit: function () {
                // status.innerHTML = "";
                // spinner.style.display = 'block';
                // canvas.style.display = 'none';
            },
            showCanvas: function () {
                // spinner.style.display = 'none';
                // status.style.display = 'none';
                canvas.style.display = 'block';
                setTimeout(
                    function () {
                        let webMac = window.allocate(window.intArrayFromString("90:da:6a:00:00:00"), 'i8', 0);

                        let wshost = `https://${wsIp}`;

                        let webUrl = window.allocate(window.intArrayFromString(wshost), 'i8', 0);

                        let webPort = wsPort;
                        if (webPort == "" || !webPort || webPort == null || webPort == NaN || webPort == undefined) {
                            // It maybe default web port..
                            if (document.location.protocol == 'https:')
                                window.Module._uweb_setSiteInfo(webUrl, 443, 80, webMac, channel);
                            else
                                window.Module._uweb_setSiteInfo(webUrl, 80, 80, webMac, channel);
                        } else {
                            if (document.location.protocol == 'https:')
                                window.Module._uweb_setSiteInfo(webUrl, webPort, webPort, webMac, channel);
                            else
                                window.Module._uweb_setSiteInfo(webUrl, webPort, webPort, webMac, channel);
                        }

                        window.Module._uweb_setP2P(true);
                        window.Module._uweb_titleBarShowHide(false);
                        window.Module._uweb_siteConnectShowHide(false);


                        if (streamType > 0)
                            window.Module._uweb_setStreamTypeChange(streamType);		// 0 : Auto Stream, 1 : Main Stream, 2 : Sub Stream (default: 0)

                        let webUser = window.allocate(window.intArrayFromString(userID), 'i8', 0);
                        let webPass = window.allocate(window.intArrayFromString(password), 'i8', 0);
                        window.Module._uweb_setUserPass(webUser, webPass);

                        window.Module._uweb_connect();

                        setTimeout(()=>{ wasmCheck = true; }, 10000);


                    },
                    100
                );


                setInterval(
                        function () {
                            if (wasmCheck) {
                                let wsConnect = window.Module._uweb_getNetworkStatus();
                                if(wsConnect){
                                    //window.Module._uweb_connect();
                                    if(timeoutCheck && timeoutCnt) {
                                        // window.alert("Logged out due to inactivity");
                                        // timeoutCnt = false;
                                        // Modal.error({ title: "Logged out due to inactivity",
                                        //                 onOk:() => {
                                        //                     window.location.href = "https://"+window.location.hostname;
                                        //                 }});

                                        //web_viewer_init();
                                    }
                                    else {
                                        timeoutCheck = true;
                                    }

                                }
                                else {
                                    timeoutCheck = false;
                                }
                            }
                        },
                        30000
                    )

                setIsLoading(false);

            },

        });
        qtLoader.loadEmscriptenModule("/js/UMiniWebViewer");
    }

    function extendTimer(){

            setInterval(() => {

                //setInterval(() => {
                let secondsToGo = 300;
                const modal = Modal.warning({
                    title: 'You will be logged out without any prolonged activity.\nPress the extend button to extend the session.',
                    content: `Time remaining before automatic logout ${secondsToGo} SEC.`,
                    width: 520,
                    okText : 'extend',
                    onOk: () => {deleteTimer()},

                });
                const timer = setInterval(() => {
                    secondsToGo -= 1;
                    modal.update({
                        content: `Time remaining before automatic logout ${secondsToGo} SEC.`,
                    });
                }, 1000);
                const setTime = setTimeout(() => {
                    clearInterval(timer);
                    modal.destroy();
                    window.location.href = "https://" + window.location.hostname;

                }, secondsToGo * 1000);

                const deleteTimer = () => {
                    clearTimeout(setTime);
                }

                //},30000);

            }, 900000);


    }

    //extendTimer()

    useEffect(() => {

        if (auth.user === null) {
            //navigate('/')
            window.location.href = "https://"+window.location.hostname;
        } else {
            //extendTimer();
            web_viewer_init();
            document.addEventListener("contextmenu", (event) => {
                event.preventDefault();
            });
            document.getElementById('qtcanvas').setAttribute("tabindex", -1);

        }


    },[]);



    return(
    <s.Container >
        <Header_
            pid={pid}
            userID={userID}
            password={password}
            channel={channel}
        />
        <div id="qtspinner"></div><div id="qtstatus"></div>
        {
            isLoading && (
                <s.StatusWrapper>
                    <s.Status >Loading...</s.Status>
                </s.StatusWrapper>
            ) }
                <s.MonitorWrapper>
                    <s.Monitor id="qtcanvas" oncontextmenu="event.preventDefault()" contenteditable="true" tabindex="-1"></s.Monitor>

                </s.MonitorWrapper>




    </s.Container>
    );

}

export default Monitoring_;

