import styled from "styled-components";
import { FLEX } from "styles/flex";
import loginBg from "assets/images/loginBg.png";
import { COLORS } from "styles/colors";

export const Container = styled.div`
  //background-color: pink;
  width: 100vw;
  min-height: 100vh;
  background: url(${loginBg}) no-repeat center center;
  background-color: #252E44;
  background-size: cover;
  ${FLEX.FlexCenterCenter};
`;

export const Wrapper = styled.div`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  width: 40%;
  height: 100%;
  gap: 12px;
  padding: 50px 0;
`;

export const LogoWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 20px;
  padding-bottom: 16px;

`;
export const LogoHeader = styled.div`
  text-align: center;
 .header-description {
   color: ${COLORS.WHITE}80;
 }
`;

export const LoginContainer = styled.section`
  width: 100%;
  background-color: #161d27;
  border-radius: 10px;
  padding: 50px;
`;

export const LoginTitle = styled.h5`
  font-weight: bold;
  font-size: 24px;
  color: ${COLORS.WHITE};
  padding-bottom: 10px;
`;

export const LoginForm = styled.form``;

export const ButtonContainer = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;
  padding-top: 20px;
`;

export const Warning = styled.span`
  font-size: 14px;
  color: ${COLORS.RED};
  ${FLEX.FlexStartCenter};
  gap: 5px;
`;

export const Button = styled.button`
  width: 100%;
  background-color: ${COLORS.BUTTONS2};
  white-space: nowrap;
  padding: 20px;
  box-sizing: border-box;
  color: ${COLORS.WHITE};
  font-weight: bold;
  font-size: 20px;
  border-radius: 3px;
  &:focus,
  &:hover {
    filter: brightness(120%) saturate(120%);
  }
`;

export const Remember = styled.span`
  font-size: 14px;
  color: ${COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  gap: 10px;
  padding-top: 10px;
`;
