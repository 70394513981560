import axios from "axios";

const ip = "setupproxy.mydw.cloud";
// const ip = "testsetupproxy.mydw.cloud";
const port = "3005";

const api = axios.create({
    //baseURL : `https://${ip}:${port}`,
    baseURL : `https://${ip}`,
});

export default api;
