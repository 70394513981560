import Router from "./router/Router";
import {useEffect} from "react";
import {AuthProvider} from "./context/auth";


function App() {


    // useEffect(() => {
    //     console.log('useEffect');
    //     const script = document.createElement("script");
    //     script.async = true;
    //     script.defer = true;
    //     script.src =
    //         "js/qtloader.min.js";
    //     document.head.appendChild(script);
    //
    //
    //
    // });


    return (<AuthProvider><Router/></AuthProvider>);
    //return(<Router/>);

}

export default App;
