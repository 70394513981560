import styled from "styled-components";
import { COLORS } from "styles/colors";

function InfoPopup({ contents }) {
  return <PopupContainer>{contents}</PopupContainer>;
}

const PopupContainer = styled.div`
  background-color: #000000;
  border-radius: 6px;
  position: relative;
  color: ${COLORS.WHITE};
  font-size: 14px;
  line-height: 22px;
  padding: 20px;
  min-width: 300px;

  :after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 0 12px 22px;
    border-color: #000000 transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -22px;
    left: 31px;
  }
`;

export default InfoPopup;
