import * as s from "./LoginStyled";
import myDWLOGO from "assets/logo/mydw.svg";
import pathfinderLOGO from "assets/logo/pathfinder.svg";
import {ModalInput} from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import WarningSVG from "assets/svg/warning.svg";
import {LogoWrapper} from "./LoginStyled";

const LoginPresenter = (props) => {

    return (
            <s.Container>
                <s.Wrapper>
                    <s.LogoHeader>
                        <s.LogoWrapper>
                            <img src={myDWLOGO} alt="my DW logo"/>
                            <img src={pathfinderLOGO} alt="pathfinder logo"/>
                        </s.LogoWrapper>
                        <span className={"header-description"}>Access your server remotely using a secured cloud relay connection.</span>
                    </s.LogoHeader>
                    <s.LoginContainer>
                        <s.LoginTitle>LOGIN</s.LoginTitle>

                        {/* login input */}
                        {props.inputLists.map((item, index) => (
                            <ModalInput
                                key={index}
                                label={item.label}
                                info={item.info}
                                contents={item.contents}
                                essential
                            >
                                <Input
                                    placeholder={item.placeholder}
                                    value={item.value}
                                    name={item.name}
                                    onChange={props.onChange}
                                    type={item.type ? item.type : "text"}
                                    onKeyPress={props.handleKeyPress}
                                />
                            </ModalInput>
                        ))}

                        <s.ButtonContainer>
                            {!props.isValidated && (
                                <s.Warning>
                                    INCORRECT LOGIN CREDENTIALS
                                    <img src={WarningSVG} alt="warning svg"/>
                                </s.Warning>
                            )}
                            {!props.isHostValidated && (
                                <s.Warning>
                                    INVALID HOST
                                    <img src={WarningSVG} alt="warning svg"/>
                                </s.Warning>
                            )}
                            {!props.isType && (
                                <s.Warning>
                                    UNSUPPORTED DEVICE
                                    <img src={WarningSVG} alt="warning svg"/>
                                </s.Warning>
                            )}
                            {!props.isVersion && (
                                <s.Warning>
                                    UNSUPPORTED FW VERSION
                                    <img src={WarningSVG} alt="warning svg"/>
                                </s.Warning>
                            )}

                            {/* button */}
                            <s.Button onClick={props.onSubmit}>Connect</s.Button>
                            <s.Remember>
                                <input
                                    type="checkbox"
                                    value={props.isRemember}
                                    onChange={props.onChangeRemember}
                                />
                                Remember ID
                            </s.Remember>
                        </s.ButtonContainer>
                    </s.LoginContainer>
                </s.Wrapper>
            </s.Container>

    );
};

export default LoginPresenter;
