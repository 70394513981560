import api from "./BaseProxyApi";

const target = "/webInfo";

export const webProxyApi = {

    webServerInfoApi: (pid) => api.post(target, {pid : pid}),

    webServerTvtSessionApi : (pid) => api.post('/tvt/session', {pid : pid})
    
};