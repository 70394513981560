import {NvrApi} from "./BaseNvrApi"
import axios from "axios";
//import AxiosDigest from 'axios-digest'
import DigestClient from "./DigestApi"

export const NvrInfoApi = {

    getTimeApi : (url) => {
        const base = axios.create({
            baseURL : `https://${url}`,
           // baseURL : `https://relay.digital-watchdog.kro.kr`,
        });
        base.interceptors.request.use(
            function (config) {
                return config;
            },
            function(error){
                        return Promise.reject(error);
            }
        )
        base.interceptors.response.use(
            function (response){
                return response;
            },
            function (error){
                //console.log(error)
                return Promise.reject(error);
            }
        )

        let target =  `/api/gettime`;

        //base.get(target).then(r=> console.log(r.data)).catch(error=> console.log(error))
        return base.get(target)
    },

    getTimeHostApi : (url, id, pw) => {
        const base = axios.create({
            //baseURL : `https://pf.mydw.cloud`
            baseURL : `https://${window.location.hostname}`
        });
        let target =  `/api/gettime`;

        return base.post(target, {
            data: {
                url: url,
                id : id,
                pw : pw
            }
        })
    } ,

    getTimeMydwFocusApi : (url, id) => {
        const base = axios.create({
            //baseURL : `https://pf.mydw.cloud`
            baseURL : `https://${window.location.hostname}`
        });
        let target =  `/api/mydw/focus/gettime`;

        return base.post(target, {
            data: {
                url: url,
                id : id
            }
        })
    } ,

    getDwInfoApi : (dwToken) => {
        const base = axios.create({
            //baseURL : `https://pf.mydw.cloud`
            baseURL : `https://${window.location.hostname}`
        });
        let target =  `/api/mydw/getInfo`;

        return base.post(target, {
            data: {
                dwToken : dwToken
            }
        })
    } ,

    getTimeMydwTvtApi : (url, id) => {
        const base = axios.create({
            //baseURL : `https://pf.mydw.cloud`
            baseURL : `https://${window.location.hostname}`
        });
        let target =  `/api/mydw/tvt/gettime`;

        return base.post(target, {
            data: {
                url: url,
                id : id
            }
        })
    } ,

    getNvrInfoApi : (url, target, id, pw) => {

        //const base =  `http://112.170.32.204:19080`
        //let target =  `/api/gettime`;
        //const axiosDigest = new AxiosDigest(id, pw);

        const base = axios.create({
            //baseURL : `https://pf.mydw.cloud`
            baseURL : `https://${window.location.hostname}`
            // baseURL : `http://${window.location.hostname}`
        });
        // const base = axios.create({
        //     baseURL : `http://112.170.32.204:12080`,
        // });
        //base.get(target).then( resp => console.log(resp.headers.get('WWW-Authenticate'))).catch(resp => console.log(resp.headers.get('WWW-Authenticate')))

        //const auth = client.addAuth().headers.Authorization
        //base.get(target).then(r=> console.log(r.data)).catch(error=> console.log(error))
        return base.post(target, {
                data : {
                    url : url,
                    id : id,
                    pw : pw
                }
        })
        //return base.get(target,{'Authorization' : auth} )

    }
}