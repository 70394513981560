export const FLEX = {
    FlexCenterCenter : `
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    FlexStartCenter : `
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
    FlexCenterStart : `
  display: flex;
  justify-content: center;
  align-items: flex-start;
`,
    FlexCenterEnd : `
  display: flex;
  justify-content: center;
  align-items: flex-end;
`,
    FlexEndCenter : `
  display: flex;
  justify-content: flex-end;
  align-items: center;
`,
    FlexEndStart : `
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`,
    FlexStartStart : `
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`,
    FlexStartEnd : `
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`,
    FlexBetweenCenter : `
  display: flex;
  justify-content: space-between;
  align-items: center;
`,
    FlexBetweenStart : `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`,
    FlexBetweenEnd : `
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`,
    FlexAroundCenter : `
  display: flex;
  justify-content: space-around;
  align-items: center;
`,
}
