import styled from "styled-components";
import { COLORS } from "../../../styles/colors";
import { FLEX } from "../../../styles/flex";

export const HeaderContainer = styled.header`
  width: 100%;
  height: 60px;
  background-color: #090B0C;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  padding: 10px 20px;
  ${FLEX.FlexBetweenCenter};
`;

export const Logo = styled.div`
  font-size: 31px;
  font-weight: bold;
  color: ${COLORS.WHITE};
  ${FLEX.FlexStartCenter};
  gap: 10px;
`;

export const LeftSide = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 20px;
  
  .refresh-button {
    position: relative;
  }
`;

export const RefreshTooltip = styled.div`
  position: absolute;
  padding: 10px;
  color: #6F788B;
  border: 1px solid #707070;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  top: 30px;
  background-color: ${COLORS.WHITE};
`;

export const RightSide = styled.ul`
  ${FLEX.FlexEndCenter};
  gap: 25px;
`;

export const RightMenu = styled.button`
  cursor: pointer;
  text-decoration: underline;
  ${FLEX.FlexEndCenter};
  gap: 8px;
  font-size: 14px;
  color: #6f788b;
  ${(props) =>
    props.blue &&
    `
    font-size: 18px;
color: #ED7724
  `};

  &:last-child {
    padding-left: 30px;
  }
`;

export const Dropdown = styled.ul`
  width: 100px;
  background-color: ${COLORS.WHITE};
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #707070;
  position: absolute;
  top: 50px;
  right: 20px;
`;

export const DropdownItem = styled.li`
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  
`;
