import styled from "styled-components";
import { FLEX } from "styles/flex";
import { InputLabel } from "components/atoms/text/Text";

export const Wrapper = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  padding: 15px 0;
  gap: 10px;
`;

export function ModalInput({ label, essential, info, contents, children }) {
  return (
    <Wrapper>
      <InputLabel
        label={label}
        essential={essential}
        info={info}
        contents={contents}
      />
      {children}
    </Wrapper>
  );
}
